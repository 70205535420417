import React, { Component } from 'react';
import scanlines from './scanlines.png';
import staticGif from './static.gif';
import './App.css';
import ReactPlayer from 'react-player/youtube';

class App extends Component {
  state = {
    firstRun: true,
    playing: false,
    controls: false,
    pip: false,
    loop: true,
    muted: false,
    title: "",
    overlay: true,
    currentChannel: "Lifestyle",
    url: "https://www.youtube.com/playlist?list=PLL7zmesNLygcSGZPgQ9ItvfF6vldx8pan"
  }

  internalPlayer = false

  playlists = {
    "Lifestyle":        {url:"https://www.youtube.com/playlist?list=PLL7zmesNLygcSGZPgQ9ItvfF6vldx8pan", commercials: undefined},
    "Early 2k":         {url:"https://www.youtube.com/playlist?list=PLL7zmesNLygdcMO9AFvvC3ZQSHXnf0Mqn", commercials: "https://www.youtube.com/playlist?list=PLL7zmesNLygc0wdnZ02ZHj3u99q7-GSLE"},
    "Nihon":            {url:"https://www.youtube.com/playlist?list=PLL7zmesNLygf110NdLVN-EfOckVLJIqBz", commercials: "https://www.youtube.com/playlist?list=PLL7zmesNLygcbqQfSpw-EEts4jxOkxbBE"},
    "Late Night":       {url:"https://www.youtube.com/playlist?list=PLL7zmesNLygdX1gi5m4aQoZHHDAYeo6Os", commercials: "https://www.youtube.com/playlist?list=PLL7zmesNLygfkQbSVclSZC8zFXZYm-KG3"},
    "Jets 'n Sunsets":  {url:"https://www.youtube.com/playlist?list=PLL7zmesNLygfpGB8PKyamf0-bN2HCyYzZ", commercials: undefined},
    "Docu":             {url:"https://www.youtube.com/playlist?list=PLL7zmesNLyge4Tq7i7Wsw1dP93O1n8NUB", commercials: undefined},
    "Art House":        {url:"https://www.youtube.com/playlist?list=PLL7zmesNLygc1sYa3vXPIp6piReqG6Gdb", commercials: undefined},
    "Golden Age":       {url:"https://www.youtube.com/playlist?list=PLL7zmesNLygct3sBBVBzaIJLrhPL-t_l8", commercials: undefined},
    "Cruise":           {url:"https://www.youtube.com/playlist?list=PLL7zmesNLygfyyNd0xNd6NveFAk37LzzE", commercials: undefined},
    "Shorts":           {url:"https://www.youtube.com/playlist?list=PLL7zmesNLygfbZ0Ag8gapkXS-T3JQx65k", commercials: undefined}
  }

  handleReady = () => {
      // console.log("ready")
      if(this.state.firstRun) {
        this.setState({firstRun: false})
      }
      const player = this.player.getInternalPlayer()
      player.setLoop(true)
      this.setState({playing: false})
      setTimeout(() => {
        const num = Math.floor(Math.random() * player.getPlaylist().length);
        player.playVideoAt(num)
        this.setState({playing: true})
      }, 250)
  }

  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing })
  }

  handleMute = () => {
    this.setState({ muted: !this.state.muted })
  }

  handleSkip = () => {
    this.setState({playing: false})
    const player = this.player.getInternalPlayer()
    const num = Math.floor(Math.random() * player.getPlaylist().length);
    player.playVideoAt(num)
    this.setState({playing: true})
  }

  handleToggleOverlay = () => {
    this.setState({ overlay: !this.state.overlay })
  }

  handlePlay = () => {
    // console.log("play")
  }

  handleStart = () => {
    // console.log("start")
  }

  handleBufferEnd = () => {
    // console.log("buffer end")
    const player = this.player.getInternalPlayer()
    const title = `${player.getVideoData().title.substring(0, 300)}.avi`;
    document.title = `BVR TV - ${title}`;
    // console.log(title)
    this.setState({"title": title, loaded: true });
  }

  handleProgress = obj => {
    const {played} = obj
    // console.log(played)
    if(played >= 0.99) {
      this.setState({loaded: false})
    }
  }

  handleDuration = seconds => {
    // console.log(seconds)
  }

  handleChannelChange = e => {
    const playlist = e.target.dataset.playlist
    
    this.setState({title: "Loading...", playing: false, loaded: false});
    // console.log("channel change")
    const player = this.player.getInternalPlayer()
    player.stopVideo()
    this.setState({currentChannel: playlist, url: this.playlists[playlist].url});
  }

  handleStop = () => {
    // console.log("stop")
    this.setState({ url: null, loaded: false, playing: false })
  }

  handleEnded = () => {
    // console.log("ended")
  }

  ref = player => {
    this.player = player
  }

  render() {
    const { loaded, playing, pip, loop, title, url, currentChannel, muted} = this.state
    return (
      <div className="App">
        <h1>ϟ — BVR TV</h1>
        
        <div className="player">
            <h2
            style={{
              position: `absolute`,
              zIndex: 3,
              padding: `4px 8px`,
              margin: 0
            }}
            >{title || "Loading..."}</h2>
            { this.state.overlay &&
            <div
              className="overlay"
              style={{
                position: `absolute`,
                backgroundRepeat: `repeat, no-repeat`,
                backgroundImage: `url(${scanlines})`,
                backgroundBlendMode: `color-dodge`,
                backgroundSize: `100%`,
                imageRendering: `pixelated`,
                height: `100%`,
                width: `100%`,
                zIndex: 2,
                opacity: 0.1,
                filter: `contrast(500%)`
              }}
            ></div>}
            { !this.state.loaded &&
            <div
              className="static"
              style={{
                position: `absolute`,
                display: `block`,
                margin: `0 auto`,
                width: `100%`,
                height: `100%`,
                backgroundImage: `url(${staticGif})`,
                backgroundSize: `33%`,
                backgroundRepeat: `repeat`,
                zIndex: 1
            }}></div> }
            <ReactPlayer
              ref={this.ref}
              onReady = {this.handleReady}
              onEnded = {this.handleEnded}
              onStart = { this.handleStart}
              onError = { error => console.log(error)}
              onPlay = {this.handlePlay}
              onStop = {() => console.log("stop")}
              onBufferEnd={ this.handleBufferEnd }
              onProgress={this.handleProgress}
              onDuration={this.handleDuration}
              playing = { playing }
              controls = { false }
              muted = { muted }
              pip = { pip }
              loop = { loop }
              height= '138%'
              width= '138%'
              volume={0.5}
              url={ url }
              style={{
                position: `absolute`,
                transform: `translate(-50%,-50%)`,
                top: `50%`,
                left: `50%`
              }}
            />
        </div>
        <div style={{display:`flex`, margin:`0 auto`, alignItems:`center`, justifyContent:`center`}}>
          {/* <button onClick={this.handlePlayPause}>{playing ? 'Pause' : 'Play'}</button> */}
          <button onClick={this.handleSkip}>Skip</button>
          <button className={muted ? `active` : null} onClick={this.handleMute}>Mute</button>
          <span style={{flexGrow:1}}></span>
          <button onClick={this.handleToggleOverlay}>Overlay</button>
        </div>
        <div style={{display:`flex`}}>{ Object.keys(this.playlists).map( playlist => (
            <button className={ currentChannel === playlist ? `active`: null}
            key={playlist} data-playlist={playlist} onClick={this.handleChannelChange}>{playlist}</button>
          ))}</div>
      </div>
    )
  }
}

export default App;
